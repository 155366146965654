import { ControlNumber } from './file-detail-search.model';

export enum ActionType {
    create = 'Save',
    edit = 'Edit'
}

export interface FileTypeInterface {
    name: string;
    value: string;
}

export interface CreateEditFile {
    fileRecId?: number;
    fileType: string;
    ownerAccountNumber: string;
    orgNumber: string;
    partySiteNumber: string;
    subscriberNumber: string;
    ownerCompanyName: string;
    ownerCompanyAddress1: string;
    ownerCompanyAddress2: string;
    ownerCompanyAddress3: string;
    ownerCompanyAddress4: string;
    ownerCompanyAddress5: string;
    ownerCompanyAddress6: string;
    ownerPostalCode: string;
    ownerCity: string;
    ownerState: string;
    ownerCountry: string;
    ownerProvince: string;
    fileCcn: string;
    ownerFileNo: string;
    partyNumber: string;
    startDate: string;
    lastUpdatedBy: string;
    lastUpdatedOn: string;
    wdrwlStatus: string;
    wdrlRecId: number;
    controlNumbers: ControlNumber[];
}

export interface CreateEditFileResponse {
    status: boolean;
    nodeId: number;
}

export const fileType: FileTypeInterface[] = [
    { name: 'FILE', value: 'FILE' },
    { name: 'AGENCY', value: 'AGENCY' }
];

export interface FileFormControlModel {
    formControl: string,
    value: string,
    isValid: boolean
}