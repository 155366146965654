import { Component, OnInit, ViewContainerRef, ViewChild,
    ComponentFactoryResolver } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup,
    Validators, FormArray, FormBuilder } from '@angular/forms';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import { CustomerDetailsService } from '../../shared/customer-details/customerdetails.service';
import { SideNavLoaderComponent } from '../../shared/side-nav-loader/side-nav-loader.component';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ActionType, CreateEditFile, FileFormControlModel, fileType } from '../create-file-detail.model';
import { CustomerDetailsModel, CustomerPartySiteModel } from '../../shared/customer-details/customerdetails.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { SidenavConfig } from '../../shared/side-nav/sidenav-config';
import { DataService } from '../../core/services/data.service';
import { FileDetailsService } from '../file-details.service';
import { Constants } from '../../constants';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-create-file',
    templateUrl: './create-file.component.html',
    styleUrls: ['./create-file.component.scss']
})
export class CreateFileComponent implements OnInit {
    @ViewChild('CreateNewFile', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

    mode: string;
    isAdmin: boolean;
    fileTypes = fileType;
    actionType = ActionType;
    isCreateFileOnly: boolean;
    accNoVerification = false;
    action = this.actionType.create;
    createEditFileForm: FormGroup;
    models: CreateEditFile[];
    controlNumber: FormArray;

    rawData; // To save a snippet of the data to get address column wise
    factory; componentRef; // Global variables for the creating and destroying the loading spinner
    isAccountNumberChanged = false; // To keep track if the account number is changed during edit action
    customerPartySiteDetails: CustomerPartySiteModel[] = [];
    selectedcustomerPartySite: CustomerPartySiteModel;

    ownerAccountDetails: CustomerDetailsModel = {
        partyNumber: null,
        accountNumber: null,
        ownerCompanyName: null,
        customerPartySiteDetails: this.customerPartySiteDetails,
    };

    model: CreateEditFile = {
        fileRecId: 0,
        fileType: '',
        ownerAccountNumber: '',
        orgNumber: '',
        partySiteNumber: '',
        subscriberNumber: '',
        ownerCompanyName: '',
        ownerCompanyAddress1: '',
        ownerCompanyAddress2: '',
        ownerCompanyAddress3: '',
        ownerCompanyAddress4: '',
        ownerCompanyAddress5: '',
        ownerCompanyAddress6: '',
        ownerPostalCode: '',
        ownerCity: '',
        ownerState: '',
        ownerCountry: '',
        ownerProvince: '',
        fileCcn: '',
        ownerFileNo: '',
        partyNumber: '',
        startDate: '',
        lastUpdatedBy: '',
        lastUpdatedOn: '',
        wdrwlStatus: 'A',
        wdrlRecId: 0,
        controlNumbers: []
    };

    constructor(
        public sidenavConfig: SidenavConfig,
        private customerDetailsService: CustomerDetailsService,
        private dataService: DataService,
        private toastService: ToastNotificationService,
        private fileService: FileDetailsService,
        private resolver: ComponentFactoryResolver,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        this.models = [];
        this.factory = this.resolver.resolveComponentFactory(SideNavLoaderComponent);
        this.isCreateFileOnly = this.dataService.getOption(Constants.IS_CREATEFILEONLY_USER);
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);

        this.createEditFileForm = this.formBuilder.group({
            fileRecId: this.formBuilder.control(null),
            ownerAccountNumber: this.formBuilder.control({ value: null, disabled: false }, {
                validators: [Validators.required], asyncValidators: [this.ownerAccountValidator()], updateOn: 'blur'
            }),
            partySiteNumber: this.formBuilder.control(null),
            orgNumber: this.formBuilder.control({ value: null, disabled: true }),
            subscriberNumber: this.formBuilder.control({ value: null, disabled: true }),
            ownerCompanyName: this.formBuilder.control({ value: null, disabled: true }),
            ownerCompanyAddress: this.formBuilder.control({ value: null, disabled: true }),
            fileCcn: this.formBuilder.control(null, [Validators.required, Validators.minLength(4), Validators.pattern('^[A-Za-z0-9]*$')]),
            ownerFileNumber: this.formBuilder.control(
                { value: "", disabled: true },
                { validators: [Validators.maxLength(15), Validators.pattern('^[A-Za-z0-9]*$')] }
            ),
            controlNumber: this.formBuilder.array([this.createItem(null)]),
            fileType: this.formBuilder.control(null)
        });

        let File_FormRecords: Array<FileFormControlModel> = [];
        this.dataService.setOption("File_FormRecords", File_FormRecords);

        if (this.sidenavConfig.data.action === 'create') {
            this.action = this.actionType.create;
        } else if (this.sidenavConfig.data.action === 'edit') {
            this.action = this.actionType.edit;
            this.setFormValues(this.sidenavConfig.data.createEditFileModel);
        }

        if (this.action == "Save" && (this.isAdmin || this.isCreateFileOnly)) { this.createEditFileForm.get('ownerFileNumber').enable(); }
        else { this.createEditFileForm.get('ownerFileNumber').disable(); }
    }

    createItem(item): FormGroup {
        if (item) {
            return this.formBuilder.group({
                controlNumberId: item.compCCNRecId,
                controlNumber: item.compCCN,
                fileRecId: item.fileRecId,
                action: Constants.UPDATED,
                disabled: true
            })
        }
        return this.formBuilder.group({
            controlNumberId: 0,
            controlNumber: "",
            fileRecId: null,
            action: Constants.ADD,
            disabled: false
        });
    }

    addNewControlNumber = (): void => {
        this.controlNumber = this.createEditFileForm.get('controlNumber') as FormArray;
        this.controlNumber.push(this.createItem(null));
    };

    removeControlNumber = (index): void => {
        this.controlNumber = this.createEditFileForm.get('controlNumber') as FormArray;
        this.controlNumber.removeAt(index);
        if (this.controlNumber.length < 1) { this.addNewControlNumber(); }
    };

    setControlNumbers(data) {
        this.controlNumber = this.createEditFileForm.get('controlNumber') as FormArray;
        this.controlNumber.removeAt(0);

        let controlNumberList = data[0].controlNumber ? data[0].controlNumber.split(',') : [""];
        let controlNumberId = data[0].controlNumberId;
        let fileRecId = data[0].fileRecId;

        controlNumberList.forEach(item => {
            if (controlNumberId != 0) {
                this.controlNumber.push(
                    this.formBuilder.group({
                        controlNumberId: controlNumberId,
                        controlNumber: item,
                        fileRecId: fileRecId,
                        action: Constants.UPDATED,
                        disabled: true
                    })
                )
            }
            else {
                this.controlNumber.push(
                    this.formBuilder.group({
                        controlNumberId: controlNumberId,
                        controlNumber: item,
                        fileRecId: fileRecId,
                        action: Constants.ADD,
                        disabled: false
                    })
                )
            }
        });
    };

    setFormValues(data: CreateEditFile) {
        const isEdit = this.sidenavConfig.data.action === 'edit';
        this.setControlNumbers(data.controlNumbers);

        this.createEditFileForm.patchValue({
            fileRecId: data.fileRecId,
            ownerAccountNumber: data.ownerAccountNumber,
            orgNumber: data.orgNumber ? data.orgNumber : null,
            partySiteNumber: data.partySiteNumber ? data.partySiteNumber : null,
            subscriberNumber: '',
            ownerCompanyName: '',
            ownerCompanyAddress: '',
            fileCcn: data.fileCcn,
            ownerFileNumber: data.ownerFileNo,
            fileType: data.fileType
        });

        // A temp solution to fix the file edit issue
        // Async validator not called on form value set
        setTimeout(() => {
            if (isEdit) { this.accNoVerification = true; }
            const control = this.createEditFileForm.get("ownerAccountNumber"); // control which has async validator
            if (control.value) { control.setValue(control.value); }
        }, 100);
    }

    getFileNumber(data) {
        const ccn = this.createEditFileForm.get('fileCcn').value;
        const strFileNumber = this.createEditFileForm.get('ownerFileNumber').value ? this.createEditFileForm.get('ownerFileNumber').value : null;

        if (ccn != null && strFileNumber == null) {
            this.customerDetailsService.autoGenerateFileNumber(ccn)
                .subscribe((fileNumber) => {
                    if (fileNumber != "0") {
                        this.createEditFileForm.get('ownerFileNumber').setValue(fileNumber);
                        this.createEditFileForm.get('ownerFileNumber').markAsDirty();
                        this.model.ownerFileNo = fileNumber;
                        this.onSubmit(data);
                    }
                    else {
                        this.dataService.navigateToInnerSideNavTop();
                        const errorMessage = `Error generating next File Number. Validate Field Entries`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.InnerSidenav;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.componentRef.destroy();
                    }
                },
                error => {
                    this.dataService.navigateToInnerSideNavTop();
                    const errorMessage = `Error generating next File Number. Validate Field Entries`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                });
        }
        else if (ccn != null && strFileNumber != null) {
            this.customerDetailsService.checkFilenumberAndCCN(ccn, strFileNumber)
            .subscribe((returncode) => {
                if (returncode == "1") {
                    this.createEditFileForm.get('ownerFileNumber').markAsDirty();
                    this.model.ownerFileNo = strFileNumber;
                    this.onSubmit(data);
                }
                else {
                    var errorMessage = `Error while checking Filenumber.`;
                    if (returncode == "98") { errorMessage = `File number doesn't match with CCN.`; }
                    else if (returncode == "97") { errorMessage = `File number already available in System.`; }
                    else if (returncode == "96") { errorMessage = `File is already withdrawn. Please try reactivating.`; }
                    else { errorMessage = `Error while checking Filenumber.`; }

                    const type = ToastNotificationType.error;
                    this.dataService.navigateToInnerSideNavTop();
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                }
            },
            error => {
                const type = ToastNotificationType.error;
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while checking Filenumber.`;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.componentRef.destroy();
            });
        }
        else {
            const type = ToastNotificationType.error;
            this.dataService.navigateToInnerSideNavTop();
            const errorMessage = `Please enter a valid CCN.`;
            const placement = ToastNotificationPlacement.InnerSidenav;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.componentRef.destroy();
        }
    }

    onClose(): void {
        if (this.sidenavConfig.data.sideNav) {
            this.sidenavConfig.data.sideNav.close();
            this.fileService.onRefreshSearchResult();
        }
    }

    getCompanyAddress() {
        let customerAddress = '';
        let subscriberNumber = '';
        this.rawData = this.selectedcustomerPartySite; // To get the address based on the individual fields while saving

        if (this.selectedcustomerPartySite) {
            if (this.selectedcustomerPartySite.address1) { customerAddress = `${this.selectedcustomerPartySite.address1}`; }
            if (this.selectedcustomerPartySite.address2) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address2}`; }
            if (this.selectedcustomerPartySite.address3) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address3}`; }
            if (this.selectedcustomerPartySite.address4) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address4}`; }
            if (this.selectedcustomerPartySite.city) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.city}`; }
            if (this.selectedcustomerPartySite.province) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.province}`; }
            if (this.selectedcustomerPartySite.state) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.state}`; }
            if (this.selectedcustomerPartySite.county) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.county}`; }
            if (this.selectedcustomerPartySite.country) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.country}`; }
            if (this.selectedcustomerPartySite.postalCode) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.postalCode}`; }
            if (this.selectedcustomerPartySite.subscriberNumber) { subscriberNumber = `${this.selectedcustomerPartySite.subscriberNumber}`; }
        }

        this.createEditFileForm.get('ownerCompanyName').setValue(this.ownerAccountDetails.ownerCompanyName);
        this.createEditFileForm.get('ownerCompanyAddress').setValue(customerAddress);
        this.createEditFileForm.get('subscriberNumber').setValue(subscriberNumber);
    }

    changePartySiteNumber(e) {
        const partySiteNumber = this.createEditFileForm.controls['partySiteNumber'].value;
        this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
            (f) => f.partySiteNumber === Number(partySiteNumber) // Number(e.target.value)
        );
        this.getCompanyAddress(); // call API for getting the customer address and set subscriber here.
    }

    getPartySiteDetails(accountNumber: string, partySiteNumber: string) {
        this.componentRef = this.entry.createComponent(this.factory);
        this.customerDetailsService.getOwnerAccountDetails(accountNumber).subscribe(result => {
            if (result && result.length > 0) {
                this.ownerAccountDetails = result[0];
                this.createEditFileForm.controls['ownerAccountNumber'].setErrors(null);
                this.createEditFileForm.get('orgNumber').setValue(result[0].partyNumber);
                this.createEditFileForm.controls['partySiteNumber'].setValue(Number(partySiteNumber));
                this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
                    (f) => f.partySiteNumber === Number(partySiteNumber)
                );
                this.getCompanyAddress(); // call API for getting the customer address and set subscriber here.
                return null;
            } else {
                this.componentRef.destroy();
                return { invalidOwnerAcount: true };
            }
        }, (error) => {
            this.componentRef.destroy();
            of({ invalidOwnerAcount: true })
        });
    }

    ccnExistsValidator(event, fControl, idx): void {
        let isControlValid = true;
        let isControlExist = false;
        let isValueChanged = true;
        let TempCcnRecord: FileFormControlModel = {
            formControl: (fControl + "_" + idx),
            value: event.target.value, isValid: false
        };

        this.componentRef = this.entry.createComponent(this.factory);
        const CcnRecords = this.dataService.getOption("File_FormRecords") as FileFormControlModel[];
        let index = CcnRecords.findIndex(x => x.formControl === (fControl + "_" + idx));

        if (index !== undefined && index >= 0) {
            isValueChanged = CcnRecords[index]?.value === event.target.value ? false : true;
            isControlValid = CcnRecords[index]?.isValid ? true : false;
            isControlExist = true;
        }

        if (fControl == "fileCcn") {
            const control = this.createEditFileForm.get(fControl);
            if (isValueChanged && control?.valid) {
                if (isControlExist) { CcnRecords.splice(index, 1); }
                control?.setErrors({ ccnExists: true });

                this.fileService.checkCcnExists(event.target.value).subscribe((result) => {
                    TempCcnRecord.isValid = result;
                    if (result) { control?.setErrors(null); }
                    else { control?.setErrors({ ccnExists: true }); }
                    CcnRecords.push(TempCcnRecord);
                    this.dataService.setOption("File_FormRecords", CcnRecords);
                }, error => { control?.setErrors({ ccnExists: true }); });
                control?.updateValueAndValidity();
            }
            else if (!isValueChanged && !isControlValid) {
                control?.setErrors({ ccnExists: true });
                control?.markAsDirty();
            }
        }
        setTimeout(() => { this.componentRef.destroy(); }, 300);
    }

    ownerAccountValidator(): AsyncValidatorFn {
        return (control: AbstractControl): | Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> =>
            control.valueChanges.pipe(debounceTime(500), take(1), switchMap((_) => {
                this.resetCompanyDetails();
                const data = this.sidenavConfig.data.createEditFileModel;
                this.componentRef = this.entry.createComponent(this.factory);

                return this.customerDetailsService.getOwnerAccountDetails(control.value)
                    .pipe(map((result: CustomerDetailsModel[]) => {
                        if (result && result.length > 0) {
                            this.ownerAccountDetails = result[0];
                            this.createEditFileForm.get('orgNumber').setValue(result[0].partyNumber);

                            if (this.accNoVerification) {
                                this.accNoVerification = false;
                                this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
                                    (f) => f.partySiteNumber === Number(data.partySiteNumber)
                                );
                                if (this.selectedcustomerPartySite) {
                                    this.createEditFileForm.get('partySiteNumber').setValue(Number(data.partySiteNumber));
                                    this.getCompanyAddress();
                                }
                            }
                            this.componentRef.destroy();
                            return null;
                        } else {
                            const type = ToastNotificationType.error;
                            this.dataService.navigateToInnerSideNavTop();
                            const placement = ToastNotificationPlacement.InnerSidenav;
                            this.createEditFileForm.get('ownerAccountNumber').setValue('');
                            const errorMessage = `No Partysite number associated to given account number`;
                            const message = new ToastNotificationMessage(errorMessage, type, placement);
                            this.toastService.sendToastNotificationMessage(message);
                            this.componentRef.destroy();
                            return { invalidOwnerAcount: true };
                        }
                    }), catchError((error) => {
                        this.componentRef.destroy();
                        return of({ invalidOwnerAcount: true });
                    }));
            }));
    }

    resetCompanyDetails() {
        this.ownerAccountDetails.customerPartySiteDetails = [];
        this.createEditFileForm.patchValue({
            partySiteNumber: null,
            subscriberNumber: '',
            orgNumber: null,
            ownerCompanyName: null,
            ownerCompanyAddress: null,
        });
    }

    saveFileNumber(data) {
        this.componentRef = this.entry.createComponent(this.factory);
        if (this.action == this.actionType.create) { this.getFileNumber(data); }
        else {
            const controlFileNo = this.createEditFileForm.get("ownerFileNumber");
            this.model.ownerFileNo = controlFileNo.value ? controlFileNo.value : null;
            this.onSubmit(data);
        }
    }

    setAddressFields() {
        this.model.ownerCity = null;
        this.model.ownerState = null;
        this.model.ownerCountry = null;
        this.model.ownerProvince = null;
        this.model.ownerPostalCode = null;
        this.model.ownerCompanyAddress1 = null;
        this.model.ownerCompanyAddress2 = null;
        this.model.ownerCompanyAddress3 = null;
        this.model.ownerCompanyAddress4 = null;
        this.model.ownerCompanyAddress5 = null;
        this.model.ownerCompanyAddress6 = null;

        if (this.rawData) {
            if (this.rawData.address1) { this.model.ownerCompanyAddress1 = this.rawData.address1; }
            if (this.rawData.address2) { this.model.ownerCompanyAddress2 = this.rawData.address2; }
            if (this.rawData.address3) { this.model.ownerCompanyAddress3 = this.rawData.address3; }
            if (this.rawData.address4) { this.model.ownerCompanyAddress4 = this.rawData.address4; }
            if (this.rawData.address5) { this.model.ownerCompanyAddress5 = this.rawData.address5; }
            if (this.rawData.address6) { this.model.ownerCompanyAddress6 = this.rawData.address6; }
            if (this.rawData.postalCode) { this.model.ownerPostalCode = this.rawData.postalCode; }
            if (this.rawData.province) { this.model.ownerProvince = this.rawData.province; }
            if (this.rawData.country) { this.model.ownerCountry = this.rawData.country; }
            if (this.rawData.state) { this.model.ownerState = this.rawData.state; }
            if (this.rawData.city) { this.model.ownerCity = this.rawData.city; }
        }
    }

    getControlNumber(index: number) {
        // Code to get the control number
        this.componentRef = this.entry.createComponent(this.factory);
        this.fileService.generateControlNumber().subscribe(result => {
            if (result) {
                this.componentRef.destroy();
                this.controlNumber = this.createEditFileForm.get('controlNumber') as FormArray;
                this.controlNumber.at(index).patchValue({
                    controlNumber: result.controlNumber,
                    disabled: true
                });
            }
            else {
                this.componentRef.destroy(); // To destroy the load spinner component
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while trying to generate the control number`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            }
        }, error => {
            this.componentRef.destroy(); // To destroy the load spinner component
            this.dataService.navigateToInnerSideNavTop();
            const errorMessage = `Error while trying to generate the control number`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.InnerSidenav;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        });
    }

    resetForm(): void {
        // This will reset the form
        this.ownerAccountDetails = {
            partyNumber: null,
            accountNumber: null,
            ownerCompanyName: null,
            customerPartySiteDetails: [],
        };

        this.createEditFileForm.patchValue({
            fileRecId: null,
            ownerAccountNumber: '',
            orgNumber: null,
            partySiteNumber: null,
            subscriberNumber: null,
            ownerCompanyName: null,
            ownerCompanyAddress: null,
            fileCcn: null,
            fileType: null
        });
        this.createEditFileForm.markAsUntouched();
        this.createEditFileForm.markAsPristine();
    }

    onSubmit(data) {
        this.setAddressFields();
        this.model.wdrlRecId = 0;
        this.model.wdrwlStatus = 'A';
        this.model.fileCcn = data.fileCcn;
        this.model.fileType = data.fileType;
        this.model.fileRecId = data.fileRecId ? data.fileRecId : 0;
        this.model.partySiteNumber = data.partySiteNumber ? data.partySiteNumber : null;
        this.model.orgNumber = this.createEditFileForm.get('orgNumber').value ? this.createEditFileForm.get('orgNumber').value : null;
        this.model.subscriberNumber = this.createEditFileForm.get('subscriberNumber').value ? this.createEditFileForm.get('subscriberNumber').value : null;
        this.model.ownerCompanyName = this.createEditFileForm.get('ownerCompanyName').value ? this.createEditFileForm.get('ownerCompanyName').value : null;
        this.model.ownerAccountNumber = this.createEditFileForm.get('ownerAccountNumber').value ? this.createEditFileForm.get('ownerAccountNumber').value : null;
        if (this.action === this.actionType.create) { this.model.startDate = this.dataService.customDateFormat(null); }
        this.model.controlNumbers = this.createEditFileForm.get('controlNumber').value;
        this.model.partyNumber = this.ownerAccountDetails.partyNumber.toString();
        this.model.lastUpdatedOn = this.dataService.getLastUpdatedDate();
        this.model.lastUpdatedBy = this.dataService.getLastUpdatedBy();

        if (this.action == this.actionType.create) {
            this.fileService.addNewFile(this.model).subscribe((result) => {
                if (result?.status) {
                    this.resetForm();
                    let timedOut = 10000;
                    this.componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToInnerSideNavTop();
                    let type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    var folderResponse = " and relevant folder has been created in ECM.";
                    if (result?.nodeId <= 0) {
                        timedOut = 15000;
                        type = ToastNotificationType.infoBlue;
                        folderResponse = " but folder creation has failed in ECM."; 
                    }
                    const infoMessage = `File ${this.model.ownerFileNo} has been created successfully ${folderResponse} `;
                    const message = new ToastNotificationMessage(infoMessage, type, placement, timedOut);
                    this.toastService.sendToastNotificationMessage(message);
                }
                else {
                    this.componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToInnerSideNavTop();
                    const errorMessage = `Error while trying to create the new file. Please try again.`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                this.componentRef.destroy(); // To destroy the load spinner component
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while trying to create the new file. Please try again.`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                this.toastService.sendToastNotificationMessage(message);
            });
        } else {
            this.fileService.updateFileDetail(this.model).subscribe((result) => {
                if (result) {
                    this.componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToInnerSideNavTop();
                    const successMessage = `The File details have been updated successfully`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
                else {
                    this.componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToInnerSideNavTop();
                    const errorMessage = `Error while trying to update the file details`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                this.componentRef.destroy(); // To destroy the load spinner component
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while trying to update the file details`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }
    }
}
