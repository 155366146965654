import { Component } from '@angular/core';

@Component({
  selector: 'app-side-nav-loader',
  templateUrl: './side-nav-loader.component.html',
  styleUrls: ['./side-nav-loader.component.scss']
})
export class SideNavLoaderComponent {

}
