import { ToastNotificationPlacement } from './toast-notification-placement.model';
import { ToastNotificationType } from './toastNotificationType.model';

export class ToastNotificationMessage {
    public cssClass = 'alert-success-green';
    public iconWrapperCssClass = 'alert-icon-wrapper-success';

    constructor(public message: string = '', public type: ToastNotificationType = ToastNotificationType.success,
        public placement: ToastNotificationPlacement = ToastNotificationPlacement.Body, public timedOut: number = 5000) {

        this.message = message;
        this.type = type;
        this.placement = placement;
        this.timedOut = timedOut;

        switch (type) {
            case ToastNotificationType.success:
                this.cssClass = 'alert-success-green';
                this.iconWrapperCssClass = 'alert-icon-wrapper-success';
                break;
            case ToastNotificationType.error:
                this.cssClass = 'alert-error-red';
                this.iconWrapperCssClass = 'alert-icon-wrapper-error';
                break;
            case ToastNotificationType.infoBlack:
                this.cssClass = 'alert-info-black';
                this.iconWrapperCssClass = 'alert-icon-wrapper-info-black';
                break;
            case ToastNotificationType.infoBlue:
                this.cssClass = 'alert-info-blue';
                this.iconWrapperCssClass = 'alert-icon-wrapper-info-blue';
                break;
        }
    }
}
