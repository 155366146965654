import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastNotificationMessage } from './toastNotificationMessage.model';
import { ToastNotificationPlacement } from './toast-notification-placement.model';

@Injectable({ providedIn: 'root' })
export class ToastNotificationService {
    private _toastNotificationMessageSource = new Subject<ToastNotificationMessage | null>();

    constructor() { }

    toastNotificationMessage$ = this._toastNotificationMessageSource.asObservable();

    sendToastNotificationMessage(toastMessage: ToastNotificationMessage) {
        this._toastNotificationMessageSource.next(toastMessage);
    }

    sendSuccessNotification(message: string, placement?: ToastNotificationPlacement): void {
        const toastNotificationMessage: ToastNotificationMessage = new ToastNotificationMessage(message);
        if (placement) { toastNotificationMessage.placement = placement; }
        this.sendToastNotificationMessage(toastNotificationMessage);
    }
}
