import { Injectable } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Observable, Subject } from 'rxjs';
import { Constants } from 'src/app/constants';
import { ClientSettingsService } from './client-settings.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _userLoggedIn = new Subject<boolean>();

    constructor(private adalService: MsAdalAngular6Service) {
        this._userLoggedIn.next(false);
    }

    setUserLoggedIn(userLoggedIn: boolean): void {
        this._userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
        return this._userLoggedIn.asObservable();
    }

    getUser(): adal.User | undefined {
        if (this.adalService.userInfo) { return this.adalService.userInfo; }
        else { return undefined; }
    }

    isLoggedIn(): boolean {
        if (this.adalService.isAuthenticated) {
            this.setUserLoggedIn(true);
            return true;
        } else {
            this.setUserLoggedIn(true);
            return false;
        }
    }

    isUser(): boolean {
        return (this.adalService.userInfo.profile.roles.includes(Constants.USER_ROLE) ||
            this.adalService.userInfo.profile.roles.includes(Constants.USER_ROLE.toLowerCase()));
    }

    isEdit(): boolean {
        return this.adalService.userInfo !== null ? this.adalService.userInfo.profile.roles.includes(Constants.EDIT_ROLE)
            || this.adalService.userInfo.profile.roles.includes(Constants.EDIT_ROLE.toLowerCase()) : false;
    }

    isAdmin(): boolean {
        return this.adalService.userInfo !== null ? this.adalService.userInfo.profile.roles.includes(Constants.ADMIN_ROLE)
            || this.adalService.userInfo.profile.roles.includes(Constants.ADMIN_ROLE.toLowerCase()) : false;
    }

    isCreateFileOnly(): boolean {
        return this.adalService.userInfo !== null ? this.adalService.userInfo.profile.roles.includes(Constants.CREATEFILEONLY_ROLE)
            || this.adalService.userInfo.profile.roles.includes(Constants.CREATEFILEONLY_ROLE.toLowerCase()) : false;
    }

    login(): void {
        this.adalService.login();
    }

    logout(): void {
        this.setUserLoggedIn(false);
        this.adalService.logout();
    }
}
