import { Injectable } from '@angular/core';
import { ClientSettings } from '../model/client-settings.model';

@Injectable({ providedIn: 'root' })

export class ClientSettingsService {

    public clientSettings: ClientSettings;

    constructor() {
        this.clientSettings = {
            webApiEndpointUrl: '',
            clientId: '',
            authority: '',
            adminConsentScope: '',
            userConsentScope: '',
            createFileOnlyConsentScope: '',
            appInsightsInstrumentationKey: '',
            isAppInsightsDisabled: true,
            ocpApimSubscriptionKey: '',
            ocpApimTrace: true,
            surveyBusterScriptUrl: '',
            surveyReactScriptUrl: '',
            idleTimeToDisplayModalInSeconds: '',
            idleTimeToLogoutAfterDisplayingModalInSeconds: '',
            welcomeBannerReleaseDate: ''
        };
    }
}
