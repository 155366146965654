import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export-confirm-dialog',
  templateUrl: './export-confirm-dialog.component.html',
  styleUrls: ['./export-confirm-dialog.component.scss']
})
export class ExportConfirmDialogComponent implements OnInit {

    title = "Export to Excel";
    confirmationInfo = "Pick an option to export the result";
    chosenOption = "true";
    constructor(public activeModal: NgbActiveModal) { }

    onOptionChange(option) {
        this.chosenOption = option.value;
    }

    onCancel() {
        this.activeModal.dismiss({ action: 'no', selectedValue: this.chosenOption });
    }

    onConfirm() {
        this.activeModal.close({ action: 'yes', selectedValue: this.chosenOption });
    }

  ngOnInit(): void {
  }

}
