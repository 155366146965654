<div class="modal-header">
    <h2 class="modal-title">{{title}}</h2>
</div>
<div class="modal-body">
    <div class="font-bold mt-4">
        <div> {{confirmationInfo}}</div>
    </div>
    <div class="mt-3 mb-2 pr-8">
        <mat-radio-group aria-label="Select an option">
            <mat-radio-button class="mr-4" value=true (change)="onOptionChange($event)" [checked]="true">Entire Result</mat-radio-button>
            <mat-radio-button value=false (change)="onOptionChange($event)">Current Result</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<div class="modal-footer">
    <button class="button button-white w-28 float-right m-1" (click)="onCancel()">Cancel</button>
    <button type="button" class="button button-blue w-28 float-right m-1" mat-button cdkFocusInitial
            (click)="onConfirm()">
        Export
    </button>
</div>
